import { createApp } from 'vue'

import store from './store/index'
import App from './App.vue'
import router from './router.js'
import VueGtag from "vue-gtag"
import VueGoogleMaps from 'vue-google-maps-community-fork'


//UI imports
import MainButton from "@/components/ui/buttons/MainButton.vue"
import IconButton from "@/components/ui/buttons/IconButton.vue"
import TableButton from "@/components/ui/buttons/TableButton.vue"

import InputField from "@/components/ui/InputField.vue"

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueGtag, {
    config: { id: process.env.GA_MEASUREMENT_ID }
})

app.use(vuetify)

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBij6VIHtB_jFTPOyWP5ATfIjG6j8BYtJc',
    },
})

//UI imports
app.component("MainButton", MainButton);
app.component("InputField", InputField);
app.component("IconButton", IconButton)
app.component("TableButton", TableButton)

app.mount('#app')