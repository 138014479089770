<template>
  <div v-if="!isMobile">
    <div id="app">
      <TopNavBar v-if="isLoggedIn"></TopNavBar>
      <SideNavBar v-if="isLoggedIn"></SideNavBar>
      <!-- add to display after login -->
      <section class="container" v-if="isLoggedIn">
        <RouterView></RouterView>
      </section>
      <RouterView v-else></RouterView>
    </div>
  </div>
  <div v-else>
    <TopMobileBar v-if="!duringInventarisation" />
    <section v-if="isLoggedIn" class="mobileContainer">
      <RouterView></RouterView>
      <BottomMobileBar @logout="logout()" :treeAmount="this.treeAmount"/>
    </section>
    <RouterView v-else></RouterView>
  </div>
</template>


<script>
import { useAuthStore } from '@/store/authStore';
import SideNavBar from '@/components/SideNavBar/SideNavBar.vue';
import TopNavBar from '@/components/TopNavBar/TopNavBar.vue'

import TopMobileBar from '@/components/Mobile/TopMobileBar/TopMobileBar.vue'
import BottomMobileBar from '@/components/Mobile/BottomMobileBar/BottomMobileBar.vue'
import isMobileMixin from '@/mixins/isMobileMixin'

export default {
  data() {
    const auth = useAuthStore();
    return {
      auth,
      userID: null,
      screenWidth: window.innerWidth,
      treeAmount: 0,
    };
  },
  mixins: [isMobileMixin],

  computed: {
    isLoggedIn() {
      return this.auth.isLoggedIn;
    },
    duringInventarisation() {
      const path = this.$route.path;
      const includesFast = path.includes('fast');
      const includesDone = path.includes('done');

      return includesFast && !includesDone;
    }
  },

  async created() {
    this.auth.tryLogin();
    this.userID = localStorage.getItem("userid");
  },
  components: {
    SideNavBar,
    TopNavBar,
    TopMobileBar,
    BottomMobileBar,
  },
  methods: {
    async logout() {
      await this.auth.logOut();
      this.$router.push("/login");
    }
  }
}
</script>

<style scoped>
@import "./assets/variables.css";
@import "./assets/styles.css";

.container {
  padding-left: calc(var(--SideNavBar-width) + 34px);
  padding-top: 1px;
  padding-right: 1.31rem;
}

.mobileContainer {
  background: #FBFBFB;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@200;300;400;600;700;900&display=swap')
</style>
