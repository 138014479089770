<template>
    <div class="nav">
        <div class="logo">
            <img src="@/assets/logo.svg" alt="Logo">
        </div>
        <SideNavButton v-for="(button, index) in SideNavButtons" :key="index" :text="button.text" :icon="button.icon"
            :path="button.path" />
    </div>
</template>


<script>
import SideNavButton from '@/components/SideNavBar/SideNavButton.vue'

export default {
    data() {
        return {
            SideNavButtons: []
        };
    },
    created() {
        let attempts = 0;
        const maxAttempts = 100;

        const fetchCompanyId = () => {
            attempts++;
            const company_id = localStorage.getItem("company_id");

            if (company_id) {
                this.SideNavButtons = [
                    { text: "Klanten", icon: require('@/components/icons/SideBar/customer_icon.svg'), path: '/customers/' + company_id },
                    { text: "Contacten", icon: require('@/components/icons/SideBar/contact_icon.svg'), path: '/contacts/' + company_id },
                    { text: "Locaties", icon: require('@/components/icons/SideBar/location_icon.svg'), path: '/locations/' + company_id },
                    { text: "Bomen", icon: require('@/components/icons/SideBar/tree_icon.svg'), path: '/trees/' + company_id },
                    { text: "Controles", icon: require('@/components/icons/SideBar/verify_icon.svg'), path: '/reports/' + company_id },
                    { text: "Rapportage", icon: require('@/components/icons/SideBar/report_icon.svg'), path: '/support' },
                    { text: "Support", icon: require('@/components/icons/SideBar/support_icon.svg'), path: '/support' }
                ];
            } else if (attempts < maxAttempts) {
                setTimeout(fetchCompanyId, 500);  // Retry after 500 milliseconds
            }
        };

        fetchCompanyId();
    },

    components: {
        SideNavButton,
    },
}
</script>

<style scoped>
.nav {
    background-color: #212B46;
    color: #FFFFFF;
    width: var(--SideNavBar-width);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
}

.h1 {
    padding: 0rem;
}

.logo {
    text-align: center;
    margin-top: 12px;
    color: #FFF;
    font-family: 'Sen';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.17438rem;
    padding-bottom: 0.5rem;
}
</style>