// /src/mixins/isMobileMixin.js
export default {
    data() {
        return {
            windowWidth: window.innerWidth
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 768;
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    }
};
