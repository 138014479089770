import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from './store/authStore';


const routes = [
    {
        path: '/login',
        component: () => import('./pages/LoginPage.vue'),
        meta: { requiresUnauth: true },
    },
    {
        path: '/reset-password',
        component: () => import('./pages/ResetPasswordPage.vue'),
        meta: { requiresUnauth: true },
    },
    {
        path: '/forgotpassword',
        component: () => import('./pages/ForgotPasswordPage.vue'),
        meta: { requiresUnauth: true },
    },
    {
        path: '/dashboard/:id/',
        component: () => import('./pages/DashboardPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/customers/:id/',
        component: () => import('./pages/CustomerPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/locations/:id/',
        component: () => import('./pages/LocationPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/contacts/:id/',
        component: () => import('./pages/ContactPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/trees/:id/',
        component: () => import('./pages/TreesPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/reports/:id/',
        component: () => import('./pages/ReportPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/support',
        component: () => import('./pages/SupportPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/profile',
        component: () => import('./pages/ProfilePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/tree-inventarisation/:id/',
        component: () => import('./pages/TreeInv.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/tree-inventarisation/:id/full/',
        component: () => import('./pages/TreeInvFull.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/tree-inventarisation/:id/fast/',
        component: () => import('./pages/TreeInvFast.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/tree-inventarisation/:id/fast/done',
        component: () => import('./pages/TreeInvSummary.vue'),
        meta: { requiresAuth: true },
    }, 

]

routes.push({ path: '/', redirect: '/login' });

const router = createRouter({
    history: createWebHistory(),
    routes,
})


router.beforeEach((to, from, next) => {
    const auth = useAuthStore();  // Initialize the auth store

    if (to.meta.requiresAuth && !auth.isLoggedIn) {
        next('/login');
    } else if (to.meta.requiresUnauth && auth.isLoggedIn) {
        const company_id = localStorage.getItem('company_id');
        if (company_id) {
            next(`/dashboard/${company_id}`);
        } else {
            next('/');
        }
    } else {
        next();
    }
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});


export default router;