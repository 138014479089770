<template>
    <div class="container">
        <router-link to="/profile">
            <div class="svgWrapper">

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z"
                        fill="#212B46" />
                    <path
                        d="M20 17.5C20 19.985 20 22 12 22C4 22 4 19.985 4 17.5C4 15.015 7.582 13 12 13C16.418 13 20 15.015 20 17.5Z"
                        fill="#212B46" />
                </svg>
            </div>
        </router-link>
        <router-link to="#" @click.prevent="toggleModal()">
            <div class="svgWrapper">

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M13 4.009C13 3.74379 12.8947 3.48943 12.7071 3.3019C12.5196 3.11436 12.2652 3.009 12 3.009C11.7348 3.009 11.4804 3.11436 11.2929 3.3019C11.1054 3.48943 11 3.74379 11 4.009L10.997 12.012C10.997 12.2772 11.1024 12.5316 11.2899 12.7191C11.4774 12.9066 11.7318 13.012 11.997 13.012C12.2622 13.012 12.5166 12.9066 12.7041 12.7191C12.8917 12.5316 12.997 12.2772 12.997 12.012L13 4.01V4.009Z"
                        fill="black" />
                    <path
                        d="M4 12.992C4 10.782 4.895 8.78199 6.343 7.33499L7.757 8.74899C6.91785 9.58809 6.34636 10.6572 6.11482 11.8211C5.88327 12.985 6.00207 14.1914 6.45618 15.2878C6.9103 16.3842 7.67933 17.3213 8.66604 17.9806C9.65274 18.6399 10.8128 18.9918 11.9995 18.9918C13.1862 18.9918 14.3463 18.6399 15.333 17.9806C16.3197 17.3213 17.0887 16.3842 17.5428 15.2878C17.9969 14.1914 18.1157 12.985 17.8842 11.8211C17.6526 10.6572 17.0812 9.58809 16.242 8.74899L17.657 7.33499C18.5872 8.26528 19.2737 9.41062 19.6556 10.6696C20.0374 11.9285 20.1029 13.2622 19.8463 14.5525C19.5896 15.8428 19.0187 17.0499 18.1841 18.0669C17.3495 19.0838 16.2769 19.8793 15.0615 20.3827C13.8461 20.8862 12.5252 21.0821 11.216 20.9532C9.90671 20.8243 8.64946 20.3744 7.55558 19.6435C6.46169 18.9127 5.56493 17.9233 4.94474 16.7631C4.32454 15.6028 4.00005 14.3076 4 12.992Z"
                        fill="black" />
                </svg>
            </div>
        </router-link>

        <!-- Logout Modal -->
        <div v-if="showModal" class="modal">
            <div class="modalContent">
                <p class="topText">Uitloggen</p>
                <p class="middleText">Weet je zeker dat je wilt uitloggen?</p>
                <div class="bottomWrapper">
                    <IconButton color="white" @click="toggleModal" text="Annuleren" clickable>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                            <path
                                d="M16.5 3C9.332 3 3.5 8.832 3.5 16C3.5 23.168 9.332 29 16.5 29C23.668 29 29.5 23.168 29.5 16C29.5 8.832 23.668 3 16.5 3ZM16.5 5C22.587 5 27.5 9.913 27.5 16C27.5 22.087 22.587 27 16.5 27C10.413 27 5.5 22.087 5.5 16C5.5 9.913 10.413 5 16.5 5ZM12.72 10.78L11.28 12.22L15.064 16L11.282 19.78L12.722 21.22L16.5 17.437L20.28 21.217L21.72 19.78L17.937 16L21.717 12.22L20.28 10.78L16.5 14.564L12.72 10.782V10.78Z"
                                fill="black" />
                        </svg>
                    </IconButton>
                    <IconButton color="red" @click="logout" text="Uitloggen" clickable>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12.9999 4.00897C12.9999 3.74376 12.8946 3.4894 12.7071 3.30187C12.5195 3.11433 12.2652 3.00897 11.9999 3.00897C11.7347 3.00897 11.4804 3.11433 11.2928 3.30187C11.1053 3.4894 10.9999 3.74376 10.9999 4.00897L10.9969 12.012C10.9969 12.2772 11.1023 12.5315 11.2898 12.7191C11.4774 12.9066 11.7317 13.012 11.9969 13.012C12.2622 13.012 12.5165 12.9066 12.7041 12.7191C12.8916 12.5315 12.9969 12.2772 12.9969 12.012L12.9999 4.00997V4.00897Z"
                                fill="white" />
                            <path
                                d="M4 12.992C4 10.782 4.895 8.78196 6.343 7.33496L7.757 8.74896C6.91785 9.58806 6.34636 10.6572 6.11482 11.8211C5.88327 12.985 6.00207 14.1914 6.45618 15.2878C6.9103 16.3841 7.67933 17.3212 8.66604 17.9805C9.65274 18.6398 10.8128 18.9917 11.9995 18.9917C13.1862 18.9917 14.3463 18.6398 15.333 17.9805C16.3197 17.3212 17.0887 16.3841 17.5428 15.2878C17.9969 14.1914 18.1157 12.985 17.8842 11.8211C17.6526 10.6572 17.0812 9.58806 16.242 8.74896L17.657 7.33496C18.5872 8.26525 19.2737 9.41058 19.6556 10.6695C20.0374 11.9285 20.1029 13.2622 19.8463 14.5525C19.5896 15.8428 19.0187 17.0499 18.1841 18.0668C17.3495 19.0838 16.2769 19.8792 15.0615 20.3827C13.8461 20.8861 12.5252 21.0821 11.216 20.9532C9.90671 20.8242 8.64946 20.3744 7.55558 19.6435C6.46169 18.9126 5.56493 17.9233 4.94474 16.763C4.32454 15.6028 4.00005 14.3075 4 12.992Z"
                                fill="white" />
                        </svg>
                    </IconButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconButton from '../ui/buttons/IconButton.vue';

import { useAuthStore } from '@/store/authStore'; // Ensure this path points to your authStore file


export default {

    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        auth() {
            return useAuthStore();
        },
    },
    mounted() {
        // Add keydown event listener to document
        document.addEventListener('keydown', this.handleKeyDown);
        console.log(this.editContact)
    },

    beforeUnmount() {
        // Remove the event listener to avoid memory leaks
        document.removeEventListener('keydown', this.handleKeyDown);
    },

    methods: {
        handleKeyDown(event) {
            if (event.key === 'Escape' || event.code === 'Escape') {
                this.closeModal();
            }
        },
        
        closeModal() {
            this.showModal = false;
        },
        toggleModal() {
            this.showModal = !this.showModal;
        },
        async logout() {
            await this.auth.logOut();
            this.toggleModal();
            this.$router.push("/login");
        },
    },
    components: { IconButton }
};
</script>


<style scoped>
.container {
    display: flex;
    width: 100%;
    height: 3.25rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #E8E8E8;
    background: #F2F2F2;
}

/* Fullscreen Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Make sure it's on top */
}

.svgWrapper:hover svg path {
    fill: #97AF08;
    /* Change this to your desired hover color */
}

.modalContent {
    background: white;
    padding: 3rem;
    border-radius: 8px;
    text-align: center;
}

.bottomWrapper {
    display: flex;
    gap: 1.3rem;
    padding-top: 1.3rem;
}

.middleText {
    padding-top: 1.3rem;
}

.topText {
    color: #212B46;
    font-family: Sen;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>