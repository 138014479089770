// src/stores/authStore.js
import { defineStore } from 'pinia';
import { supabase } from '@/supabase';
import { useDatabaseStore } from '@/store/databaseStore'; // Import databaseStore here


export const useAuthStore = defineStore({
    id: 'auth',

    state: () => ({
        email: null,
        userid: null,
        error: null,
    }),

    getters: {
        isLoggedIn: (state) => !!state.userid,
        getAuthError: (state) => state.error,
    },

    actions: {
        async signupWithEmail(form) {
            try {
                const { error } = await supabase.auth.signUp({
                    email: form.email,
                    password: form.password,
                });
                if (error) throw error;
                alert("You've been registered successfully");
                await this.signInWithEmail(form);
            } catch (error) {
                alert(error.error_description || error.message);
            }
        },

        async signInWithEmail(payload) {
            try {
                const { data, error } = await supabase.auth.signInWithPassword({
                    email: payload.email,
                    password: payload.password,
                })
                if (error) throw error;
                this.setUser({
                    email: data.user.email,
                    userid: data.user.id
                });

                localStorage.setItem('userid', data.user.id);
                localStorage.setItem('email', data.user.email);

                await supabase.from('users')
                    .select('company')
                    .eq('id', data.user.id)
                    .then(({ data, error }) => {
                        if (error) {
                            throw error;
                        }
                        localStorage.setItem('company_id', data[0].company)
                    })
            } catch (error) {
                this.setAuthError(error);
            }
        },

        tryLogin() {
            const userid = localStorage.getItem("userid");
            const email = localStorage.getItem("email");
            if (userid) {
                this.setUser({
                    email: email,
                    userid: userid
                });
            }
        },

        async logOut() {
            try {
                const { error } = await supabase.auth.signOut();
                if (error) throw error;
                this.setUser({
                    email: null,
                    userid: null
                });

                const databaseStore = useDatabaseStore();
                // Call the ClearAll action from the databaseStore
                databaseStore.clearAll();

                localStorage.removeItem('userid');
                localStorage.removeItem('email');
                localStorage.removeItem('company_id')
            } catch (error) {
                console.error(error.error_description || error.message);
            }
        },

        async resetPassword(payload) {
            console.log("Reset password payload", payload);
            try {
                const options = {
                    redirectTo: 'https://treeble.app/reset-password'
                };
                const { error } = await supabase.auth.resetPasswordForEmail(payload, options);
                if (error) {
                    console.error(error);
                    throw error; // Explicitly throw the error
                }
                // Successfully sent reset email
            } catch (error) {
                console.error("Caught an error in Pinia store:", error);
                throw error; // Re-throw the error to be caught by the caller
            }
        },

        // Actions that replace what you previously had as mutations
        setUser(payload) {
            this.email = payload.email;
            this.userid = payload.userid;
        },

        setAuthError(error) {
            this.error = error;
            console.error(error);
        }
    }
});
