<template>
    <div class="input-wrapper">
        <label v-if="label" :for="inputId" class="custom-label">{{ label }}</label>
        <input :id="inputId" :placeholder="placeholder" @input="handleInput($event)" class="custom-input" :type="type"
            :style="{ width: forcedWidth }" />
    </div>
</template>

<script>
export default {
    name: "InputField",
    props: {
        input: {
            type: String,
        },
        label: {
            type: String,
            default: null
        },
        inputId: {
            type: String,
            default: null
        },
        forcedWidth: {
            type: String,
            default: '100%'
        },
        placeholder: {
            type: String
        },
        type: {
            type: String,
            default: "text"
        },
    },
    data() {
        return {
            internalValue: this.value
        };
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue;
        }
    },
    methods: {
        handleInput($event) {
            this.$emit('update:modelValue', $event.target.value);
        }
    }
};
</script>

  
<style scoped>
.input-wrapper {
    display: flex;
    flex-direction: column;
}

.custom-label {
    color: var(--label-form-field, #000429);
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1rem;
    margin-bottom: 0.5rem;
}

.custom-input {
    padding: 10px 15px;
    border-radius: 0.3125rem;
    border: 1px solid var(--hs-accent-1, #97AF08);
    background: #FFF;
    outline: none;
    transition: border-color 0.3s;
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.custom-input::placeholder {
    color: #808080;
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

input {
    width: 100%;
    /* or any specific width you want */
}
</style>
  