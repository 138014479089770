// src/stores/databaseStore.js
import { defineStore } from 'pinia';
import { supabase } from '@/supabase';

export const useDatabaseStore = defineStore({
  id: 'database',  // Adjusted the store ID to 'database'

  state: () => ({
    customers: [],
    locations: [],
    contacts: [],
    personalData: [],
    companyData: [],
    trees: [],
    species: [], //also myspecies
    allSpecies: [],
    reports: [],
  }),

  getters: {
    // Any getters you might need
  },

  actions: {
    clearAll() {
      this.clearCompanyData();
      this.clearContacts();
      this.clearCustomers();
      this.clearLocations();
      this.clearPersonalData();
      this.clearTrees();
      this.clearSpecies();
      this.clearReports();
    },

    async fetchPersonalData(payload) {
      // Check if customers array is empty
      if (this.personalData.length === 0) {
        await supabase
          .from("users")
          .select("*")
          .eq("id", payload.userID)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }

            this.setPersonalData(data); // Set the fetched data to the store
          })
          .catch((error) => {
            console.error(error.error_description || error.message);
            throw error;
          });
      }
      return this.personalData; // Return the customers from the store
    },

    setPersonalData(personalData) {
      this.personalData = personalData;
    },

    clearPersonalData() {
      this.personalData = []

    },

    async fetchCompanyData(payload) {
      // Check if customers array is empty
      if (this.companyData.length === 0) {
        await supabase
          .from("companies")
          .select("*")
          .eq("id", payload.companyID)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }

            this.setCompanyData(data); // Set the fetched data to the store
          })
          .catch((error) => {
            console.error(error.error_description || error.message);
            throw error;
          });
      }
      return this.companyData; // Return the customers from the store
    },

    setCompanyData(companyData) {
      this.companyData = companyData;
    },

    clearCompanyData() {
      this.companyData = []

    },

    async fetchCustomers(payload) {
      // Check if customers array is empty
      this.clearCustomers()

      if (this.customers.length === 0) {
        await supabase
          .from("customers")
          .select("*")
          .eq("company_id", payload.company_id)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }

            this.setCustomers(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.customers; // Return the customers from the store
    },

    setCustomers(customersData) {
      this.customers = customersData;
    },

    clearCustomers() {
      this.customers = []
    },

    async fetchLocations(payload) {
      // Check if locations array is empty
      this.clearLocations()

      if (this.locations.length === 0) {
        await supabase
          .from("locations")
          .select("*")
          .eq("company_id", payload.company_id)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }

            this.setLocations(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.locations; // Return the locations from the store
    },

    setLocations(locationsData) {
      this.locations = locationsData;
    },

    clearLocations() {

      this.locations = []


    },

    async fetchContacts(payload) {
      // Check if contacts array is empty
      this.clearContacts()

      if (this.contacts.length === 0) {
        await supabase
          .from("contacts")
          .select("*")
          .eq("company_id", payload.company_id)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }

            this.setContacts(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.contacts; // Return the contacts from the store
    },

    setContacts(contactsData) {
      this.contacts = contactsData;
    },

    clearContacts() {
      this.contacts = []
    },

    async fetchTrees(payload) {
      // Check if customers array is empty
      this.clearTrees()

      if (this.trees.length === 0) {
        await supabase
          .from("trees")
          .select("*")
          .eq("company_id", payload.company_id)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }
            this.setTrees(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.trees; // Return the customers from the store
    },

    setTrees(treesData) {
      this.trees = treesData;
    },

    clearTrees() {
      this.trees = []
    },

    async fetchAllSpecies() {
      this.clearAllSpecies();
      if (this.allSpecies.length === 0) {
        await supabase
          .from("all_species")
          .select("*")
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }
            this.setAllSpecies(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.allSpecies; // Return the species from the store
    },

    setAllSpecies(allSpeciesData) {
      this.allSpecies = allSpeciesData;
    },

    clearAllSpecies() {
      this.allSpecies = []
    },

    async fetchSpecies(payload) {
      // Check if customers array is empty
      this.clearSpecies()

      if (this.species.length === 0) {
        await supabase
          .from("species")
          .select("*")
          .eq("company_id", payload.company_id)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }
            this.setSpecies(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.species; // Return the customers from the store
    },

    setSpecies(speciesData) {
      this.species = speciesData;
    },

    clearSpecies() {
      this.species = []
    },

    async fetchReports(payload) {
      // Check if customers array is empty
      this.clearReports()

      if (this.reports.length === 0) {
        await supabase
          .from("reports")
          .select("*")
          .eq("company_id", payload.company_id)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }
            this.setReports(data); // Set the fetched data to the store
          })
          .catch((error) => {
            alert(error.error_description || error.message);
            throw error;
          });
      }
      return this.reports; // Return the customers from the store
    },

    setReports(reportsData) {
      this.reports = reportsData;
    },

    clearReports() {
      this.reports = []
    },
  }
});
