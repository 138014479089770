<template>
    <div class="modal-overlay">
        <div class="modal-content">

            <div class="topWrapper">
                <div class="logoContainer">
                    <img src="@/assets/logo.svg" alt="Logo" class="logo">
                </div>
                <CloseButton @click="closeOverlay" class="closeButton" />
            </div>


            <div class="modal-option" @click="goHome">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon">
                    <path
                        d="M11,21V16.74C10.53,16.91 10.03,17 9.5,17C7,17 5,15 5,12.5C5,11.23 5.5,10.09 6.36,9.27C6.13,8.73 6,8.13 6,7.5C6,5 8,3 10.5,3C12.06,3 13.44,3.8 14.25,5C14.33,5 14.41,5 14.5,5A5.5,5.5 0 0,1 20,10.5A5.5,5.5 0 0,1 14.5,16C14,16 13.5,15.93 13,15.79V21H11Z" />
                </svg>
                Alle modules
            </div>
            <div class="modal-option" @click="logout">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon">
                    <path
                        d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M10 6A2 2 0 0 1 12 8A2 2 0 0 1 10 10A2 2 0 0 1 8 8A2 2 0 0 1 10 6M10 13C7.33 13 2 14.33 2 17V20H11.5A6.5 6.5 0 0 1 11.03 18.1H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.5 14.9 11 14.95 11.5 15.03A6.5 6.5 0 0 1 12.55 13.29C11.61 13.1 10.71 13 10 13M17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13M17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5M14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z" />
                </svg>
                Logout
            </div>
        </div>
    </div>
</template>

<script>
import CloseButton from '@/components/ui/buttons/CloseButton.vue';

export default {
    components: {
        CloseButton
    },
    methods: {
        logout() {
            this.$emit('logout');
            this.closeOverlay();

        },
        goHome() {
            this.$emit('allModules');
            this.closeOverlay();
        },
        closeOverlay() {
            this.$emit('close');
        }
    }
}
</script>


<style scoped>
.topWrapper {
    display: flex;
    justify-content: space-between;
    /* Aligns children to each side */
    align-items: center;
    position: relative;
    /* For absolute positioning of the close button */
    padding-top: 2rem;
    font: Patua One;
    padding-bottom: 1rem;
}

.logoContainer {
    flex: 1;
    /* Takes up the full width of the container */
    text-align: center;
    /* Centers the content */

}

.logoText {
    color: var(--Primary---Dark-blue, #212B46);
    font-family: Patua One;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.closeContainer {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.80);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem;
    z-index: 1000; /* High z-index to overlay on everything */

    /* Padding for top, bottom, left and right */
}

.modal-content {
    background-color: white;
    position: relative;
    /* Needed for absolute positioning of children */

    text-align: center;
    width: 100%;
    /* Take full width of the overlay container */
    border-radius: 1rem;

    /* Subtract top and bottom padding from height */
    overflow-y: auto;
    /* Enable scrolling for overflowing content */

    padding-bottom: 10rem;
}

.modal-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    font: Source Sans Pro;
}

.icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    /* Additional styling for the SVG icon if needed */
}
</style>


  