<template>
    <div class="topContainer">
        <div class="backButtonContainer">
            <BackButton v-if="duringInventarisation" :breadcrumb="duringInventarisation" @click="buttonClicked" />
        </div>
        <p v-if="onPage" class="topText">{{ this.text }}</p>
        <template v-else>
            <img src="@/assets/logo.svg" alt="Logo" class="svgWrapper">
        </template>
        <div class="spacer"></div> <!-- Invisible spacer -->
    </div>
</template>

<script>
import BackButton from '@/components/Mobile/buttons/BackButton.vue'

export default {
    props: {
        backButtonPresent: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: 'Treeble'
        }
    },
    methods: {
        buttonClicked() {
            this.$emit('backButtonClicked')
        }
    },
    computed: {
        onPage() {
            const pathSubstrings = ['tree-inv', 'another', 'third', 'fourth', 'fifth'];
            return pathSubstrings.some(subPath => this.$route.path.includes(subPath));
        },
        duringInventarisation() {
            const path = this.$route.path;
            const includesFast = path.includes('fast');
            const includesDone = true //path.includes('done');
            console.log("test", includesFast, includesDone)
            return includesFast && !includesDone;
        }
    },
    components: {
        BackButton
    }
}
</script>

<style scoped>
.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #212B46;
    padding: 0.75rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    z-index: 10;
}

.backButtonContainer {
    /* Adjust width to match BackButton size */
    min-width: 2rem; 
}

.spacer {
    /* Invisible spacer to balance the layout */
    min-width: 2rem;
    visibility: hidden;
}

.title,
.topText {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 1rem;
    flex: 1; /* This makes the text take up the available space */
}

.title {
    font-family: Sen;
    letter-spacing: 0.15119rem;
}

.topText {
    font-family: Sen;
    font-weight: 700;
}

.svgWrapper {
    width: 2rem;
    height: 2rem;
}
</style>