<template>
    <MenuModal v-if="showMenuModal" @close="toggleModal" @logout="logout" @allModules="allModules" />

    <div class="bottomContainer">
        <div class="iconWrapper" @click="toggleModal()">

            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                    d="M7.16668 5.81915C6.94566 5.81915 6.7337 5.90695 6.57742 6.06323C6.42114 6.21951 6.33334 6.43147 6.33334 6.65249C6.33334 6.8735 6.42114 7.08546 6.57742 7.24174C6.7337 7.39802 6.94566 7.48582 7.16668 7.48582H13.8333C14.0544 7.48582 14.2663 7.39802 14.4226 7.24174C14.5789 7.08546 14.6667 6.8735 14.6667 6.65249C14.6667 6.43147 14.5789 6.21951 14.4226 6.06323C14.2663 5.90695 14.0544 5.81915 13.8333 5.81915H7.16668ZM6.33334 9.99999C6.33334 9.77897 6.42114 9.56701 6.57742 9.41073C6.7337 9.25445 6.94566 9.16665 7.16668 9.16665H13.8333C14.0544 9.16665 14.2663 9.25445 14.4226 9.41073C14.5789 9.56701 14.6667 9.77897 14.6667 9.99999C14.6667 10.221 14.5789 10.433 14.4226 10.5892C14.2663 10.7455 14.0544 10.8333 13.8333 10.8333H7.16668C6.94566 10.8333 6.7337 10.7455 6.57742 10.5892C6.42114 10.433 6.33334 10.221 6.33334 9.99999ZM7.16668 12.5142C6.94566 12.5142 6.7337 12.602 6.57742 12.7582C6.42114 12.9145 6.33334 13.1265 6.33334 13.3475C6.33334 13.5685 6.42114 13.7805 6.57742 13.9367C6.7337 14.093 6.94566 14.1808 7.16668 14.1808H13.8333C14.0544 14.1808 14.2663 14.093 14.4226 13.9367C14.5789 13.7805 14.6667 13.5685 14.6667 13.3475C14.6667 13.1265 14.5789 12.9145 14.4226 12.7582C14.2663 12.602 14.0544 12.5142 13.8333 12.5142H7.16668Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.8333 9.99999C18.8333 14.6025 15.1025 18.3333 10.5 18.3333C5.8975 18.3333 2.16667 14.6025 2.16667 9.99999C2.16667 5.39749 5.8975 1.66666 10.5 1.66666C15.1025 1.66666 18.8333 5.39749 18.8333 9.99999ZM17.1667 9.99999C17.1667 11.7681 16.4643 13.4638 15.214 14.714C13.9638 15.9643 12.2681 16.6667 10.5 16.6667C8.73189 16.6667 7.0362 15.9643 5.78596 14.714C4.53572 13.4638 3.83334 11.7681 3.83334 9.99999C3.83334 8.23188 4.53572 6.53619 5.78596 5.28594C7.0362 4.0357 8.73189 3.33332 10.5 3.33332C12.2681 3.33332 13.9638 4.0357 15.214 5.28594C16.4643 6.53619 17.1667 8.23188 17.1667 9.99999Z"
                    fill="white" />
            </svg>
            <p class="bottomText">Menu</p>
        </div>
        <div class="iconWrapper" @click="openTreeSummary()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M13.3333 4.16666L15.8333 6.66666L14.1667 7.5L17.5 10.8333L15 11.6667L18.3333 15H10.8333M12.5 17.5V15M6.66667 10.8333L5 9.16666M6.66667 10L8.33333 8.33333M6.66667 17.5V6.66666M4.85333 13.3333C4.48873 13.312 4.13322 13.211 3.81184 13.0375C3.49045 12.864 3.21098 12.6222 2.99309 12.3291C2.77519 12.0359 2.62417 11.6986 2.55065 11.3409C2.47712 10.9831 2.48287 10.6136 2.5675 10.2583C2.26539 10.0067 2.02688 9.68746 1.87133 9.32639C1.71578 8.96533 1.64757 8.57267 1.67225 8.1803C1.69693 7.78793 1.8138 7.40691 2.01337 7.06819C2.21294 6.72946 2.48958 6.44258 2.82083 6.23083C2.53031 5.71471 2.43246 5.11221 2.54472 4.53067C2.65698 3.94914 2.97205 3.42634 3.4338 3.05544C3.89556 2.68454 4.47399 2.48962 5.06605 2.50541C5.65812 2.5212 6.22534 2.74667 6.66667 3.14166C7.10809 2.7473 7.67513 2.52236 8.26686 2.50687C8.85859 2.49139 9.43661 2.68637 9.89806 3.0571C10.3595 3.42784 10.6744 3.95029 10.7868 4.53145C10.8992 5.11262 10.8017 5.71481 10.5117 6.23083C10.843 6.44251 11.1197 6.72933 11.3193 7.06802C11.519 7.40671 11.6359 7.78772 11.6607 8.18009C11.6855 8.57246 11.6173 8.96514 11.4618 9.32624C11.3064 9.68734 11.0679 10.0067 10.7658 10.2583C10.8533 10.6257 10.8564 11.008 10.775 11.3767C10.6935 11.7455 10.5296 12.0909 10.2956 12.3872C10.0615 12.6835 9.76334 12.923 9.42349 13.0875C9.08365 13.2521 8.71093 13.3376 8.33333 13.3375H5L4.85333 13.3333Z"
                    stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p class="bottomText">Bomen</p>
            <span class="notification-badge">{{ this.treeAmount }}</span>
        </div>
    </div>
</template>

<script>
import MenuModal from '@/components/Mobile/BottomMobileBar/MenuModal.vue'

export default {
    data() {
        return {
            showMenuModal: false,
        }
    },
    props: {
        treeAmount: {
            type: Number,
            default: 0
        }
    },
    created() {
    },

    methods: {
        async logout() {
            this.$emit('logout');
        },
        openTreeSummary() {
            const company_id = localStorage.getItem("company_id");
            if (company_id) {
                this.$router.replace(`/tree-inventarisation/${company_id}/fast/done`);
            } else {
                this.$router.replace("/");
            }
        },

        toggleModal() {
            this.showMenuModal = !this.showMenuModal;
        },
        allModules() {
            const company_id = localStorage.getItem("company_id");
            if (company_id) {
                this.$router.replace(`/tree-inventarisation/${company_id}/`);
            } else {
                this.$router.replace("/");
            }
        },
    },
    emits: ['logout'],
    components: {
        MenuModal
    }
}
</script>

<style scoped>
.iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.bottomText {
    color: #FFF;
    font-family: Sen;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 0.975rem */
}

.bottomContainer {
    display: flex;
    width: 100%;
    height: 3.125rem;
    padding: 0.5rem 1rem 0.375rem 1rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: var(--Primary---Dark-blue, #212B46);
    box-shadow: 2px -1px 4px 0px rgba(0, 0, 0, 0.25);

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.notification-badge {
    position: absolute;
    top: -0.8rem;
    /* Adjust as necessary */
    right: 3rem;
    /* Adjust as necessary */
    padding: 5px 10px;
    border-radius: 50%;
    background-color: #D3711E;
    color: white;
    font-family: Sen;
    font-size: 0.8rem;
}
</style>
