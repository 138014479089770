<template>
    <router-link :to="path" class="SideNavButton-link" :class="{ active: isActive }">
        <div class="SideNavButton" :class="{ active: isActive }">
            <div class="icon">
                <img :src="icon" alt="Icon" width="25" height="24">
            </div>
            <div class="text">
                <p>{{ text }}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null
        },
        path: {
            type: String,
            default: null
        }
    },
    computed: {
        isActive() {
            return this.$route.path === this.path;
        }
    },
}
</script>

<style scoped>
.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.SideNavButton.active {
    background-color: #97AF08;
}

.SideNavButton:hover {
    background-color: #97AF08;
}

.SideNavButton {
    display: flex;
    width: 6.5rem;
    padding: 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
}

.SideNavButton-link,
.SideNavButton-link:hover,
.SideNavButton-link:active,
.SideNavButton-link:focus {
    text-decoration: none;
}


.text {
    color: #FFF;
    font-family: Sen;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04063rem;
}
</style>
