<template>
    <div v-if="path">
        <router-link :to="path" class="router-link">
            <div class="button" :class="{ 'clickable': clickable, 'clicked': clicked }" @click="handleClick">
                <slot class="svgWrapper"></slot>
                <p class="buttonText">{{ text }}</p>
            </div>
        </router-link>
    </div>
    <div v-else>
        <div class="button" :class="{ 'clickable': clickable, 'clicked': clicked }" @click="handleClick">
            <slot class="svgWrapper"></slot>
            <p class="buttonText">{{ text }}</p>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        text: {
            type: String,
            default: null,
        },
        clickable: {
            type: Boolean,
            default: true
        },
        clicked: {
            type: Boolean,
            default: false
        },
        path: {
            type: String,
        },
    }
}
</script>

<style scoped>
.router-link {
    text-decoration: none;
}

.button:hover {
    color: var(--HS---Accent-3);
    /* This will be the hover color for the SVG */
}

.button {
    color: #212B46;
}

.button {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-right: 1px solid #C6C6C6;
    border-radius: 0.1875rem;
}

.button.clickable:hover {
    cursor: pointer;
}

.button.clicked {
    color: var(--HS---Accent-3, #768808);
    /* This will be the hover color for the SVG */
}

.button.clicked .buttonText {
    color: var(--HS---Accent-3, #768808);
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.155rem;
    border-bottom: 2px solid var(--HS---Accent-3, #768808);
}

.button:hover .buttonText {
    color: var(--HS---Accent-3, #768808);
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.155rem;
    border-bottom: 2px solid var(--HS---Accent-3, #768808);
}

.buttonText {
    color: #212B46;
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.155rem;
}</style>