<template>
    <div class="button" :class="[clickable ? 'clickable' : '', colorClass]">
        <slot></slot> <!-- Slot for SVG content -->
        <p class="buttonText" :class="textColorClass">{{ text }}</p>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
        },
        clickable: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'orange', // default color
            validator: value => ['red', 'white', 'orange'].includes(value) // only allow these values
        }
    },
    computed: {
        colorClass() {
            return this.color;
        },
        textColorClass() {
            return (this.color === 'red' || this.color === 'orange') ? 'text-white' : 'text-black';
        }
    },
}
</script>

<style scoped>
.button {
    /* common styles */
    display: flex;
    width: 10rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border-radius: 1.25rem;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
}

.button.orange {
    background: var(--hs-secondary-3, #D3711E);
}

.button.red {
    background: #930000;
}

.button.white {
    background: white;
    border: 1px solid #212B46;
}

.button.clickable:hover {
    cursor: pointer;
}

.text-black {
    color: black;
}

.text-white {
    color: white;
}

.buttonText {
    font-family: Sen;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0875rem;
}
</style>
