<template>
    <div @click="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14 7L9 12L14 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        breadcrumb: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['goBack'],
    methods: {
        goBack() {
            if(!this.breadcrumb) {
                this.$router.go(-1); // This will take you back to the previous page
            } else {
                this.$emit('goBack')
            }
        }
    }
}
</script>

<style scoped></style>