<template>
    <div class="button" :class="{ 'clickable': clickable }">
        <p class="buttonText">{{ text }}</p>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: null,
        },
        clickable: {
            type: Boolean,
            default: false
        },
    }
}
</script>


<style scoped>
.button {
    display: flex;
    height: 2.5rem;
    min-width: 7.5rem;
    justify-content: center;
    align-items: center;

    border-radius: 1.25rem;
    background: var(--hs-secondary-3, #D3711E);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
}

.button.clickable:hover {
    cursor: pointer;
}


.buttonText {
    color: #FFF;
    font-family: Sen;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.1rem;
    padding: 0.5rem 1rem;
    /* Adjust padding for better spacing */
    text-align: center;
    white-space: nowrap;
    /* Prevents the text from wrapping */
    overflow: hidden;
    /* Ensures text doesn't overflow button */
    text-overflow: ellipsis;
    /* Adds ellipsis if text is too long */
}</style>